/*login form*/
.RaLoginForm-form-5 {
    width: 40% !important;
    margin: 0 auto !important;
}
.MuiCardActions-root{
    width: 40% !important;
    margin: 20px auto !important;
}

.jss83 {
    width: 40% !important;
    margin: 0 auto !important;
}

.RaCreate-noActions-67 {
    margin-top: 0em !important;
}

.MuiSnackbarContent-message {
    display: none;
}

.MuiSnackbar-root {
    /* hide notificaion bar for Object */
    display: none !important;
}

.RaCreate-noActions-57 {
    margin-top: 0em !important;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

/* Style for Dialog */
* {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

body {
    font-family: "Noto Sans TC", sans-serif;
    height: 100vh;
    background-color: #F9F9F9;
}

.click,
.click {
    background: #2981bc;
    color: white;
    font-size: 22px;
    font-family: arial;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    border: 1px solid #0059b3;
}

.popup_box {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 400px;
    height: 150px;
    background: #f2f2f2;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    padding: 40px;
    border: 1px solid #b3b3b3;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: none;
}

.popup_box i {
    font-size: 60px;
    color: #eb9447;
    border: 5px solid #eb9447;
    padding: 20px 40px;
    border-radius: 50%;
    margin: -10px 0 20px 0;
}

.popup_box h1 {
    font-size: 30px;
    color: #1b2631;
    margin-bottom: 5px;
}

.popup_box label {
    font-size: 23px;
    color: #404040;
}

.popup_box .btns {
    margin: 40px 0 0 0;
    float: right;
}

.btns .btn1,
.btns .btn2 {
    background: #999999;
    color: white;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #808080;
    padding: 10px 13px;
    margin-left: 20px;
}

.btns .btn2 {
    margin-left: 0px;
    background: #ff3333;
    border: 1px solid #cc0000;
}

.btns .btn1:hover {
    transition: 0.5s;
    background: #8c8c8c;
}

.btns .btn2:hover {
    transition: 0.5s;
    background: #e60000;
}

/* EOF Style for Dialog */

.jss55 {
    margin-top: 0em !important;
}

.MuiFormLabel-root {
    margin-top: 4px;
}

.MuiDrawer-root {
    padding-top: 10px !important;
}

.MuiFormControl-root {
    width: 100%;
}

.First-Search {
    margin: 0 auto;

    padding-top: 10px;
    width: 90% !important;
    border: none !important;
    text-decoration: none;
}

.RaList-main-40 {
    margin-top: 20px;
}

.RaList-content-41 {
    margin-top: 20px;
}

.order {
    border: none !important;

    /* border-radius: 40px !important; */
    /* display: grid; */
    height: 100%;
}

/*----------------karevor----------*/
/*.RaSidebar-docked-13{display: none !important}*/
.order__row {
    height: 350px !important;
}

.row {
    float: right !important;
    width: 70%;
    height: 350px !important;
}

.MuiTablePagination-toolbar {
    border: none !important;

    display: none !important;
}

.RaListToolbar-toolbar-45 {
    display: none !important;
}

@media (max-width: 600px) {

    .progress-div {
        float: right;
    }

    .jss14 .jss15 .jss24 .jss25 {
        display: none !important
    }


    /*//upload*/
    .MuiButton-iconSizeMedium {
        display: none !important;
    }

    .btn2 {
        margin-bottom: 10px !important
    }

    RaSidebar-docked-13 {
        display: none !important
    }

    .RaSidebar-drawerPaper-24 {
        display: none !important;
    }


    .MuiBackdrop-root {
        display: none !important
    }

    .MuiTableCell-sizeSmall {

        font-size: 12px !important;
        padding: 1px 5px 5px 0px !important;
    }


    .MuiButton-containedSecondary {
        padding: 6px 15px !important
    }

    .button__save {
        padding: 6px 15px !important
    }
.jss61{padding-right: 7px}
    .jss71 {
        padding: 1px 7px 7px 4px !important;
    }

    /* .MuiDrawer-paper {
      z-index: 818812812812812 !important;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: red;
    }
    .RaSidebar-drawerPaper-24 {
      z-index: none !important;
      width: 100px;
      height: 100px;
      background-color: #0080ff;
      position: none !important;
    } */
    .popup_box {
        position: fixed;
        width: 208px !important;
        height: 185px !important;
        top: 60% !important;
        left: 52% !important;
        font-size: 18px !important;
    }

    .popup_box h1 {
        font-size: 23px !important;
    }

    .btns {
        width: 100%;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .popup_box i {
        font-size: 25px !important
    }

    .popup_box table {
        font-size: 15px;
        width: 100% !important;

    }

    .popup_box tr img {
        margin-right: 10px !important;
    }

    .order__row {
        overflow: hidden !important;
        width: 100%;
    }

    .order {
        overflow: hidden !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .order__main {
        width: 100%;
        height: auto;
    }

    .active__travel {
        display: none;
    }

    .MuiPaper-rounded {
    }

    .RaList-main-40 {
        margin: 0;
    }

    .row {
        overflow: hidden !important;
        width: 100%;
        height: 100%;
        margin-top: 10px;
    }

    .icons__logout {
        padding: 0px;
    }
}

.active__travel {
}

/*

.RaDatagrid-thead-64 {
  float:left
}
.RaDatagrid-tbody-65 {
  float:right
}
.RaDatagrid-thead-64 th {
  display: block;
  border: 1px solid black;
}
.RaDatagrid-tbody-65 td {
  display: block;
  border: 1px solid black;
}
.RaDatagrid-thead-64 th:first-child {
  display:none;
}
td:first-child {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid black;
  border-width: 1px 0 1px 1px;
  padding-right: 5px;
}
td:first-child img {
  display: block;
}
.views-table {
  margin-left: 90px;
  border: none;
}
.view-content {
  display: inline-block;
  position: relative;    
} */
