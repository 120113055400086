#main-content {
    width: 100%;
    border: none !important;
    height: 100%;
}

.MuiPaper-rounded {
    border: none !important;
    width: 100% !important;
    height: 100% !important;
}

.RaLayout-contentWithSidebar-3 {
    box-shadow: 0px 0px 8px -3px #000000;
    margin: 20px 0;
    border-radius: 40px;
    width: 100% !important;
    background-color: rgb(255, 255, 255);
}

.jss3 {
    box-shadow: 0px 0px 8px -3px #000000;
    margin: 20px 0;
    border-radius: 40px;
    width: 100% !important;
    background-color: rgb(255, 255, 255);
}

.jss4 {
    margin-top: 30px !important
}

.order__main {
    float: left;
    border: none !important;
    border: none !important;
    width: 30%;
    height: auto;
    overflow: hidden !important;
}

.main {
    position: relative;
    border: none !important;
    padding: 10px 0;
    display: grid;
    padding-top: 20px;
    width: 100% !important;
    height: 66.6vh !important;

    border-radius: 40px;
}

.main__htext {
    font-size: 30px;
}

.main__pickup {
    height: 30px;

    width: 100%;
}

.main__destination {
    margin-top: 11px !important;
    height: 30px;
    width: 100%;
}

.main__message {
    height: 30px;
    color: rgba(0, 0, 0, 0.451) !important;

    width: 100%;
}

.main__cartype {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center
}

/* .cartype {
  width: 50%;
  height: 100%;
} */


.main__button {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPopover-paper {
    top: 475px !important;
    right: 300px !important;
    height: 120px !important;
    width: 130px !important;
}

.button__save {
    color: #060606 !important;
    font-weight: 600;
    background-color: rgb(249 201 56) !important;
    padding: 7px 40px !important;
    margin: 0 30px;
}

.main__selectdriver {
    margin-top: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.selectdriver {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.451) !important;
    width: 100%;
    padding-bottom: 5px;
    font-size: 17px !important;
    border: none !important;
    border-bottom: 1px solid #828282 !important;
}

.selectdriver:hover {

    border-bottom: 2px solid black !important;

}

.cartype {
    margin-bottom: 30px
}

@media (max-width: 600px) {
    .flatpickr-input {
        height: 37px
    }

    .selectdriver {
        background-color: white !important;
        color: rgba(0, 0, 0, 0.451) !important;
        width: 80%;
        padding-bottom: 5px;
        border-radius: 0px !important;
        font-size: 17px !important;
        border: none !important;
        border-bottom: 1px solid #828282 !important;
    }

    .main {
        align-items: center;
        padding: 10px;
        border-radius: 40px;
        height: 350px !important;
    }

    .MuiFormControlLabel-root {
        margin-right: 5px;
    }

    .MuiPopover-paper {
        top: 395px !important;
        left: 153px !important;
        height: 150px !important;
        width: 200px !important;
    }

    .main__htext {
        display: none;
    }

    .MuiTypography-root {
        /* color: rgb(249 201 56) !important; */
    }

    .RaListToolbar-toolbar-42 {
        display: none !important;
        border-radius: 40px;
    }

    .MuiToolbar-root {
        display: none !important;
    }

    .main__button {
        display: flex;
        justify-content: center;
    }

    .RaLayout-contentWithSidebar-3 {
        box-shadow: 0px 0px 8px -3px #000000;

        border-radius: 1px;
    }

    .jss3 {
        box-shadow: 0px 0px 8px -3px #000000;

        border-radius: 1px !important;

    }
}
