.MuiAppBar-colorPrimary {
    margin: 0 auto;
    border-radius: 35px;
    width: 100% !important;

    background-color: white !important;
}

.MuiAppBar-positionFixed {
    position: relative !important;
}

.RaLayout-appFrame-2 {
    margin-top: 20px !important;
    padding: 0 25px !important;
}

.jss2 {
    margin-top: 0px;

    padding: 20px 20px !important;
}

.jss43 {
    display: none !important
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 50px;
    background-color: rgb(255, 255, 255);
    position: relative;
    height: 90px;
    border-radius: 50px;
}


.header__logo {
    width: 20%;
    height: 55%;
}

.logo__img {
    width: 100%;
    height: 100%;
}

.bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 20px;
    color: #d1d1d1;
    transition: color 0.2s;
}

.bm-item:hover {
    color: #555555;
}

.bm-burger-button {
    position: absolute;
    width: 36px;
    height: 30px;
    right: 25px;
    top: 34px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: rgb(249 201 56);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    display: none;

}

/* General sidebar styles */
.bm-menu {
    width: 100%;
    background: white;
    position: relative;
    top: 52%;
    transform: translate(-50%, -50%);
    left: 50%;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    font-size: 23px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    color: #b8b7ad;
    width: 100%;

}

/* Styling of overlay */
.bm-overlay {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}

/*.header__menu {*/
/*  height: 100%;*/
/* display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  width: 30%;*/
/*  font-size: 25px;*/
/*}*/
/*.p1{margin-left: 15px*/
/*;border-right: 1px solid rgba(0, 0, 0, 0.42);*/
/*  border-right-height:50px ;*/
/*;padding-right: 10px;margin-right: 10px;text-align: center;*/
/*}*/
.burger__menu {
    display: none
}

.header__icons {
    color: black;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40%;
    height: 100%;
    padding: 0 10px;
}


.icons__logout {
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #060606;
    font-weight: 600;
    background-color: rgb(249 201 56);
    border-radius: 4px;

    cursor: pointer;
    padding: 10px 5px;
    margin-left: 15px;
}

.icons__logout:hover {
    transition: all 0.5s;
    background-color: black;
    color: rgb(249 201 56);
    box-shadow: 0px 0px 15px -3px #000000;

    transform: scale(1.03);
}

.icons__logout .logout__icon {
    font-weight: 900;
    margin-left: 10px;
}

.logout__text {
    margin-left: 10px;

    margin-right: 20px;
}

.languageFlag {
    border-radius: 50%;
    cursor: pointer;
}

.languageFlag:hover {
    transform: scale(1.2);
    box-shadow: 0px 0px 10px -3px #000000;
}

.jss2 {
    margin-top: 0px !important;
    padding-top: 20px !important
}

@media (max-width: 600px) {
    .menu__div {
        background-color: #0b97c4;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap
    }

    .burger__menu {
        display: block !important;
        margin-right: 300px !important
    }

    .header {
        margin: 0;
        padding: 0;
        display: flex;

        justify-content: flex-start;
    }

    .main {
        height: 100%;
    }

    .main__htext {
        display: none;
    }

    .header__logo {
        margin-left: 30px !important;
        width: 209px;

        height: 45%;
    }

    .header__icons {
        display: none;
    }

    .icons__logout {
        background-color: white;
        padding: 0px !important;
        margin: 0px !important;
    }

    .logout__icon {
    }

    .logout__text {
        font-weight: normal;
        font-size: 23px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .icons__flag {
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start
    }

    .languageFlag {
        width: 25px !important;
        height: 25px !important;
    }
}

